.project_filter_button {
  /* position: fixed !important;
  top: 64px;
  right: 8px; */
  padding: 2px 4px !important;
  min-width: 44px !important;
  height: 44px;
  border-radius: 22px !important;
}

.issue_filter_button {
  /* position: fixed !important;
  top: 110px;
  right: 8px; */
  padding: 2px 4px !important;
  min-width: 44px !important;
  height: 44px;
  border-radius: 22px !important;
}

