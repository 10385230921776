.send_message_block {
  
  width: 100%;
  /* background-color: rgba(128, 128, 128, .3); */
  /* margin-left: -24px; */

  position: sticky;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 100;
}