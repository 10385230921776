.message_block_triangle_inbox {
  position: absolute;
  left: -1px;
  bottom: 4px;
  border: 6px solid transparent;
  border-right: 6px solid rgb(240, 240, 240);
  border-bottom: 6px solid rgb(240, 240, 240); 
}

.message_block_triangle_sent {
  position: absolute;
  right: -1px;
  bottom: 4px;
  border: 6px solid transparent;
  border-left: 6px solid rgb(237, 247, 237);
  border-bottom: 6px solid rgb(237, 247, 237); 
  z-index: 10;
}

