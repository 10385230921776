.border-button {  
  transition: all 0.5s ease;
  box-shadow: 0px 0px 0px 0px #1976d2;
  margin: 3px; /* Increased margin since the box-shado expands outside the element, like outline */
}

.border-button.active {
  animation:  box-shadow 1s linear infinite;
}
 
@keyframes box-shadow {
  0% {
    box-shadow: 0px 0px 0px 1px #1976d2;
  }
  50% {
    box-shadow: 0px 0px 0px 3px #1976d2;
  }
  0% {
    box-shadow: 0px 0px 0px 1px #1976d2;
  }
} 


