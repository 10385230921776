.mention_input {
  text-align: center;
  height: 40px;

}

.mention_input textarea {
  padding: 4px 8px;
  border-radius: 16px 16px;
}

.mention_input textarea:focus {
  outline: none !important;
  border:2px solid #1976d2;;
}


.comments-wrapper.--side-panel {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:row;  
  overflow: hidden;
}

.comments-wrapper.--side-panel.mentions, .comments-wrapper.--side-panel.mentions__control {
  min-height: 60px;
}

.comments-wrapper.--side-panel.mentions__control {
  max-width: 220px;
}

.comments-wrapper.--side-panel.--side-overflow {
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
}


.comments-wrapper.comments-actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
