.search_helper {
  position: absolute;
  bottom: -22px;
  left: 20px;
  transition: .3s;
  opacity: 0;
  color: gray;
  white-space: nowrap;
  visibility: hidden;
}

.search_helper.active {
  transition: .3s;
  opacity: 1;
  visibility: visible;
}

.search_icon_wrapper {
  display: inline-block;
  padding: 2px 2px;
  width: 30px;
  height: 30px;
  vertical-align: center;  
  border-radius: 15px;
  color: gray;
  background-color: white;
  transition: .3s;
}

.search_icon_wrapper.active {  
  cursor: pointer;
  color: white;
  background-color: #0091EE;
  transition: .3s;
}

.reset_icon_wrapper {
  display: inline-block;
  visibility: hidden;
  
  transition: .3s;
  opacity: 0;
}

.reset_icon_wrapper.active {  

  visibility: visible;

  transition: .3s;
  opacity: 1;
}